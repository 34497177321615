import React from "react";

import { Props } from "../../types/Heading03";

/**
 * 見出し3、h3をメインに想定
 */
export const Presenter: React.VFC<Props> = ({
  label,
  TagName = "h2",
  bigLabel,
  isCenter = false,
}) => {
  return (
    <div>
      <span
        className={`block text-[40px] font-semibold leading-[56px] text-primary pc:text-[56px] ${
          isCenter && `text-center`
        }`}
      >
        {bigLabel}
      </span>
      <TagName
        className={`mt-2 text-sm font-bold text-black pc:text-base ${
          isCenter && `text-center`
        }`}
      >
        {label}
      </TagName>
    </div>
  );
};
