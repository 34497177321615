import React from "react";
type Props = {
  text: string;
  className?: string;
  design: number; //1~3
  // 1:primary: グレー
  // 2:secondary: ボーダー緑色
  // 3:primary: ボーダー白色
};
const TextLoop: React.FC<Props> = ({ text, className, design = 1 }) => {
  return (
    <div
      className={`z-0 overflow-hidden text-[96px] pc:text-[160px] ${className}`}
    >
      <div
        style={{
          //ボーダーをtext-shadowで描く
          textShadow:
            design === 2
              ? "0.4px 0.4px 0 #2B823F,-0.4px -0.4px 0 #2B823F,0.4px -0.4px 0 #2B823F,-0.4px 0.4px 0 #2B823F"
              : design === 3
              ? "0.4px 0.4px 0 #FFF,-0.4px -0.4px 0 #FFF,0.4px -0.4px 0 #FFF,-0.4px 0.4px 0 #FFF"
              : "none",
        }}
        className={`w-auto animate-slide whitespace-nowrap font-semibold
          leading-none ${
            design === 2 || design === 3 ? `bg-clip-text` : `text-[#FAFAFA]`
          }`}
      >
        {[...Array(10).keys()].map((item, index) => (
          <p key={index} className="inline-block pl-4">
            {text}
          </p>
        ))}
      </div>
    </div>
  );
};
export default TextLoop;
