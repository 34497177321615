import { graphql, PageProps } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Breadcrumb } from "../components/Breadcrumb";
import { FlowList } from "../components/FlowListItem";
import { Heading01 } from "../components/Heading01";
import { Heading03 } from "../components/Heading03";
import { Layout } from "../components/Layout/index";
import { RuledLineList01 } from "../components/RuledLineList01";
import * as style from "../styles/iframe.module.scss";
import bgimgPc from "../images/h1/h1company.png";
import bgimgSp from "../images/h1/h1company_sp.png";
import { Triangle } from "../components/Triangle";
import TextLoop from "../components/TextLoop";

const CompanyPage: React.VFC<PageProps<GatsbyTypes.CompanyIndexQuery>> = ({
  data,
}) => {
  const acf = data.wpPage?.companyAcf;
  const info = acf?.information && Object.values(acf?.information)[1];
  const history = acf?.history && Object.values(acf?.history)[1];
  const iframe =
    '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6489.697405067809!2d139.663578!3d35.582126!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5f047af97e54373e!2z77yI5qCq77yJ44Of44K544Ku!5e0!3m2!1sja!2sjp!4v1650512990599!5m2!1sja!2sjp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';

  return (
    <Layout title="会社概要" description="ミスギの会社概要ページです。">
      <Heading01
        label="COMPANY"
        smallLabel="会社概要"
        bgimg={[bgimgPc, bgimgSp]}
      />
      <Breadcrumb labels={[{ url: "", text: "会社概要" }]} />
      <div className="relative flex flex-col py-10 px-8 pc:flex-row pc:py-[80px] pc:px-0">
        <div className="z-10 flex-shrink-0 overflow-hidden rounded-2xl pc:w-[45%] pc:rounded-none">
          <StaticImage src="../images/IMG_0037.png" alt="" />
        </div>
        <div className="mt-6 pc:ml-24 pc:w-[500px]">
          <Heading03 label="ミッション" bigLabel="MISSION" />
          {acf?.mission?.catch && (
            <h3
              className="mt-6 text-2xl font-bold leading-[40px] pc:mt-12 pc:text-3xl pc:leading-[60px]"
              dangerouslySetInnerHTML={{
                __html: acf?.mission?.catch,
              }}
            ></h3>
          )}

          {acf?.mission?.text && (
            <p
              className="mt-8 text-base leading-[2.37] pc:mt-10"
              dangerouslySetInnerHTML={{
                __html: acf?.mission?.text,
              }}
            ></p>
          )}
        </div>
        <Triangle className="hidden pc:bottom-[120px] pc:right-[90px] pc:block pc:h-[811px] pc:w-[540px]" />
      </div>
      <div className="bg-bggrey pt-12 pb-[70px] pc:pb-40 pc:pt-16">
        <div className="l-container-sm">
          <Heading03 label="代表挨拶" bigLabel="MESSAGE" isCenter={true} />
          <div className="mt-8 flex flex-col pc:mt-[72px] pc:flex-row pc:items-center">
            <div className="flex-shrink-0  pc:w-[45%]">
              {acf?.message?.catch && (
                <h3
                  className="text-2xl font-bold leading-[40px] pc:text-3xl pc:leading-[60px]"
                  dangerouslySetInnerHTML={{
                    __html: acf?.message?.catch,
                  }}
                ></h3>
              )}
            </div>
            <div className="mt-8 pc:ml-24 pc:mt-0 pc:w-[500px]">
              {acf?.message?.text && (
                <p
                  className="text-base leading-[2.37]"
                  dangerouslySetInnerHTML={{
                    __html: acf?.message?.text,
                  }}
                ></p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="relative pb-14 pt-[100px] pc:pb-24 pc:pt-[216px]">
        <div className="absolute top-0 -translate-y-1/2">
          <TextLoop
            className="text-[112px] text-white pc:text-[200px]"
            text="MISUGI"
            design={2}
          />
        </div>
        <div className="l-container-sm">
          <Heading03 label="会社情報" bigLabel="INFORMATION" isCenter={true} />
          <dl className="mt-12 pc:mt-20">
            {info?.map(
              (row, index) =>
                row && (
                  <RuledLineList01
                    key={index}
                    title={row[0] || ""}
                    content={row[1] || ""}
                  />
                )
            )}
          </dl>
        </div>
      </div>
      <div className="bg-bggrey py-12 pc:py-[72px]">
        <Heading03 label="沿革" bigLabel="HISTORY" isCenter={true} />
        <div className="mt-14">
          <StaticImage
            src="../images/enkaku_pc.png"
            alt=""
            className="hidden pc:block"
          />
          <StaticImage
            src="../images/enkaku_sp.png"
            alt=""
            className="mx-auto pc:hidden"
            layout="fullWidth"
          />
        </div>
        <div className="l-container-sm">
          <dl className="pt-14">
            {history?.map(
              (item, index) =>
                item && (
                  <FlowList
                    key={index}
                    head={item[0]}
                    body={item[1]}
                    isFinal={index === history.length - 1}
                  />
                )
            )}
          </dl>
        </div>
      </div>
      <div className="mt-14 mb-16 pc:my-[120px]">
        <div className="l-container-sm relative">
          <Heading03 label="アクセス" bigLabel="ACCESS" />
          <div className="flex flex-col pc:flex-row pc:pr-12">
            <dl className="mt-6 pc:mt-10">
              <div className="text-base">
                <dt className="text-primary">住所</dt>
                <dd className="mt-3">
                  〒211-0006
                  <br />
                  神奈川県川崎市中原区丸子通1-636-1プロコープ1A
                </dd>
              </div>
              <div className="mt-8 text-base">
                <dt className="text-primary">アクセス</dt>
                <dd className="mt-3">
                  東急東横線「新丸子駅」より徒歩３分
                  <br />
                  丸子橋交差点より車１分
                  <p className="text-sm">
                    ※駐車場はございませんが短時間であれば建物横に駐車可
                  </p>
                </dd>
              </div>
              <div className="mt-8 text-base">
                <dt className="text-primary">電話番号</dt>
                <dd className="mt-3">TEL：044-431-1134 FAX：044-431-1168</dd>
              </div>
            </dl>
            <div className="mt-8 pc:absolute pc:top-0 pc:left-1/2 pc:mt-0 pc:h-full pc:w-[50vw]">
              <div
                className={`${style.google_map}`}
                dangerouslySetInnerHTML={{ __html: iframe }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CompanyPage;

export const query = graphql`
  query CompanyIndex {
    wpPage(id: { eq: "cG9zdDoxMQ==" }) {
      companyAcf {
        message {
          catch
          text
        }
        mission {
          catch
          text
        }
        information {
          header
          body
        }
        history {
          header
          body
        }
      }
    }
  }
`;
