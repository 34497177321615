import React from "react";
import { Props } from "../../types/FlowListItem";

/**
 * 罫線付きリストを返します。
 * dlでラップしてください
 * HISTORY沿革に使用。
 */
export const Presenter: React.VFC<Props> = ({
  head,
  body,
  isFinal = false,
}) => {
  return (
    <div className={`abc flex ${isFinal || `pb-8`}`}>
      <dt className="w-[60px] flex-shrink-0 text-base font-medium text-primary pc:w-[110px]">
        {head}
      </dt>
      <dd
        className={`relative pl-8 before:absolute before:left-0 before:top-[0.4em] before:z-10 before:h-2 before:w-2 
         before:rounded-full before:bg-primary before:content-[''] ${
           isFinal ||
           `after:absolute after:left-[3px] after:top-[0.4em] after:z-0 after:h-[calc(100%+36px)] after:w-[2px]
         after:bg-[#E0E0E0] after:content-['']`
         }`}
      >
        {body}
      </dd>
    </div>
  );
};
