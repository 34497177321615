import React from "react";

import { Props } from "../../types/RuledLineList01";

/**
 * 罫線付きリストを返します。dlでラップしてください。
 * 会社概要などに使用
 * dtは太文字です
 */
export const Presenter: React.VFC<Props> = ({ title, content }) => {
  return (
    <div className="flex border-b border-solid border-[#E0E0E0] py-6 pr-4">
      <dt className="min-w-[100px] flex-shrink-0 text-base font-bold text-dark pc:min-w-[100px]">
        {title}
      </dt>
      <dd
        className="ml-[40px] text-base text-dark pc:ml-[80px]"
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      ></dd>
    </div>
  );
};
